import { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import LoaderComponent from "../Components/Common/Loader/Loader";

const CreateNewQuote = lazy(() => import("../Pages/Create New Quote/Index"));

const Index = () => {
  return (
    <Suspense fallback={<LoaderComponent />}>
      <Routes>
        <Route
          path="/"
          element={
            <CreateNewQuote />
          }
        />
        <Route
          path="/:quote_id"
          element={
            <CreateNewQuote />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Index;
